import React from "react";
import styled from "styled-components";
import { useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const InputContainer = styled.div`
  margin-bottom: 10px;
  padding: 0 15px;
`;

const StyledInput = styled.textarea`
  resize: none;
  display: block;
  box-sizing: border-box;
  padding: 8px 12px;
  color: #ffffff;
  background-color: #5e2e6c;
  border: 1px solid rgba(255, 255, 255, 0.12);
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
  height: 120px;
  border-radius: 7px;

  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;

  :focus {
    border: 1px solid #4d68ff;
    outline: none;
  }

  :disabled {
    pointer-events: none;
  }
`;

const StyledLabel = styled.label`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
`;

const StyledErrorMessage = styled.p`
  color: #ffffff;
  font-size: 12px;
  margin-top: 5px;
`;

const Input = ({ label, name, placeholder, disabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={(controlerProps) => (
        <InputContainer>
          <StyledLabel> {label}</StyledLabel>
          <StyledInput
            defaultValue={""}
            onChange={controlerProps.field.onChange}
            placeholder={placeholder}
            value={controlerProps.field.value}
            disabled={disabled}
          />
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <StyledErrorMessage>
                {message.replace(name, placeholder).replace("*", "")}
              </StyledErrorMessage>
            )}
          />
        </InputContainer>
      )}
    />
  );
};

export default Input;
