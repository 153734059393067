import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { MobileMenuContext } from "../../pages/index";
import HeaderBurger from "../HeaderBurger";

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(53deg, #021740, #540558);
`;

const MobileHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 20px;
  font-family: "Open Sans", sans-serif;
`;

const StyledContainer = styled(Container)`
  max-width: 100%;
  margin-top: 10px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 50px;
`;

const HamburgerButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 100%;
  z-index: 999;
`;

const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const MobileMenuLink = styled(Link)`
  font-size: 24px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
  margin-bottom: 50px;
  margin-right: 0;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
`;

const MobileMenu: React.FC = () => {
  const { mobileMenu, setMobileMenu } = useContext(MobileMenuContext);

  return (
    <MenuContainer>
      <MobileHeader>
        <StyledContainer>
          <Row>
            <Col col={8} noGutter>
              <Link to="/">
                <StaticImage src="../../images/Group.svg" alt="logo" />
              </Link>
            </Col>
            <Col col={4} noGutter>
              <HamburgerButtonContainer>
                <HeaderBurger
                  showMenu={mobileMenu.show}
                  setShowMenu={setMobileMenu}
                />
              </HamburgerButtonContainer>
            </Col>
          </Row>
        </StyledContainer>
      </MobileHeader>
      <MobileContent>
        <Container>
          <StyledRow>
            <Col col={12}>
              <MobileMenuLink to="/">Home</MobileMenuLink>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col col={12}>
              <MobileMenuLink to="/">About</MobileMenuLink>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col col={12}>
              <MobileMenuLink to="/">Options</MobileMenuLink>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col col={12}>
              <MobileMenuLink to="/">Contact</MobileMenuLink>
            </Col>
          </StyledRow>
        </Container>
      </MobileContent>
    </MenuContainer>
  );
};

export default MobileMenu;
