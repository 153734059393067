import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { StaticImage } from 'gatsby-plugin-image';

const SubHeroSection = styled.div`
  padding: 80px 0;
  background-color: #f8f8f8;
`;

const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-item: center;
  ${media.md`
    flex-direction: row-reverse;
 `}
`;

const SubHeroDetail = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 35px;
  font-weight: 400;

  ${media.md`
    font-size: 20px;
  `}

  ${media.lg`
    font-size: 25px;
    line-height: 45px;
  `}
`;

const SubHeroImageContainer = styled.div``;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const SubHero = () => {
  return (
    <SubHeroSection>
      <Container>
        <StyledRow>
          <StyledCol col={10} md={6} noGutter>
            <SubHeroImageContainer>
              <StaticImage
                src="../../images/12.9-inch-iPad-Pro-Copy-2-p-800.png"
                alt="intro"
              />
            </SubHeroImageContainer>
          </StyledCol>
          <StyledCol col={10} md={6} noGutter>
            <SubHeroDetail>
              This is the Opportunity to drive home The key business value
              propella has to offer. Create a concise and potent Message that
              will draw new users in and help them realise that the platform Is
              real and ready to use
            </SubHeroDetail>
          </StyledCol>
        </StyledRow>
      </Container>
    </SubHeroSection>
  );
};

export default SubHero;
