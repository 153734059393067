import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  width: 135px;
  height: 60px;
  box-sizing: border-box;
  border: none;
  border-radius: 60px;
  margin-top: 24px;
  padding: 20px 40px;
  background-color: #c203e8;
  background-image: linear-gradient(69deg, #3b38ec, #5a20d8);
  color: #fff;
  font-size: 18px;
  text-align: center;
  border: none;

  cursor: pointer;
  transition-duration: 0.3s;

  :focus {
    outline: none;
  }

  :disabled {
    pointer-events: none;
  }
`;

const Button = ({ children, type , disabled = false }) => {
  return (
    <StyledButton disabled={disabled} type={type}>
      {children}
    </StyledButton>
  );
};

export default Button;
