import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { GatsbyImage } from 'gatsby-plugin-image';

type RowProps = {
    reversed: string
    }

const ServicesContainer = styled.div`
  width: 100%;
`;

const IntroImageContainer = styled.div`
  padding: 30px 0;
`;

const ServiesDetailContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ServicesTitle = styled.h1`
  font-size: 25px;
  font-weight: bold;
  line-heigth: 44px;
  margin-bottom: 30px;
`;

const ServicesDescription = styled.div`
  font-size: 14px;
  line-height: 30px;

  ${media.md`
        font-size: 18px;
    `}
`;

const StyledRow = styled(Row)<RowProps>`
display: flex;
justify-content: center;
align-items: center;
  flex-direction: ${(props) => (props.reversed ? 'row-reverse' : 'row')};
`;

const Services = ({ title, image, reversed }) => {
  return (
    <ServicesContainer>
      <Container>
        <StyledRow alignItems="center" reversed={reversed}>
          <Col col={10} md={6} noGutter>
            <IntroImageContainer>
              <GatsbyImage image={image} alt="intro" />
            </IntroImageContainer>
          </Col>
          <Col col={10} md={6} noGutter>
            <ServiesDetailContainer>
              <ServicesTitle>{title}</ServicesTitle>
              <ServicesDescription>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse varius enim in eros elementum tristique. Duis
                cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
                commodo diam libero vitae erat. Aenean faucibus nibh et justo
                cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus
                tristique posuere.
              </ServicesDescription>
            </ServiesDetailContainer>
          </Col>
        </StyledRow>
      </Container>
    </ServicesContainer>
  );
};

export default Services;
