import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

type MenuStateProps = {
  show: boolean;
};

type HamburgerButtonProps = {
  showMenu: boolean;
  setShowMenu: (showMenu: MenuStateProps) => void;
};

const HammburgContainer = styled.button`
  border: 0;
  background: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  font-size: 24px;
  color: #ffffff;
`;

const HamburgerButton: React.FC<HamburgerButtonProps> = ({
  showMenu,
  setShowMenu,
}) => {
  return (
    <HammburgContainer
      onClick={() => setShowMenu({ show: !showMenu })}
      aria-label="Hamburger"
    >
      {!showMenu ? (
        <FontAwesomeIcon icon={faBars} size="1x" />
      ) : (
        <FontAwesomeIcon icon={faTimes} size="1x" />
      )}
    </HammburgContainer>
  );
};

export default HamburgerButton;
