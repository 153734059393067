import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import { graphql, useStaticQuery } from 'gatsby';

type HeroBackgroundImageProps = {
  className: string;
  children: any;
};

const HeroSection = styled.div`
  background-image: linear-gradient(53deg, rgb(2, 23, 64), rgb(84, 5, 88));
  height: 700px;
  position: relative;

  @media screen and (max-width: 479px) {
    height: 800px;
    padding-top: 20px;
  }
`;

const StyledRow = styled(Row)``;

const HeroContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 10px;

  ${media.sm`
    margin-top: 65px;
  `}
`;

const HeroHeader = styled.h1`
  font-size: 40px;
  font-weight: bold;
  line-height: 60px;
  color: #fff;
  font-family: 'Open Sans', sans-serif;

  margin-top: 20px;
  margin-bottom: 10px;

  ${media.lg`
    font-size: 50px;
    line-height: 80px;
  `}
`;

const HeroSubHeader = styled.p`
  font-size: 18px;
  line-height: 35px;
  color: #fff;
  font-family: 'Open Sans', sans-serif;

  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 35px;

  ${media.sm`
    font-size: 20px;
    padding-right: 60px;
  `}

  ${media.md`
    font-size: 22px;
  `}

  ${media.lg`
    font-size: 25px;
    line-height: 45px;
  `}
`;

const CtaButton = styled.button`
  width: 240px;
  margin-top: 20px;
  padding: 30px 50px;
  border-radius: 60px;
  background-color: #c203e8;
  background-image: linear-gradient(69deg, #3b38ec, #5a20d8);
  color: #fff;
  font-size: 20px;
  text-align: center;
  border: none;

  ${media.lg`
    width: 300px;
  `}
`;

const HeroBackgroundImage: React.FC<HeroBackgroundImageProps> = ({
  className,
  children,
}) => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "qwqwqw.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const backupImageData = getImage(data.desktop);

  const bgImage = convertToBgImage(backupImageData);

  return (
    <BackgroundImage Tag="div" className={className} {...bgImage}>
      {children}
    </BackgroundImage>
  );
};

const StyledHeroBackgroundImage = styled(HeroBackgroundImage)`
  height: 100%;

  ::after {
    background-repeat: no-repeat;
    background-attachment: scroll;
    object-fit: fill;

    background-position: 80% 120% !important;
    background-size: 800px !important;

    ${media.sm`
    background-position: 80% 1100% !important;
    background-size: 1200px !important;
    `}

    ${media.md`
    background-position: 65% 280% !important;
    background-size: 1100px !important;
    `}

  ${media.lg`
    background-position: center !important;
    background-size: 1411px !important;
  `}
  }
`;

const Hero = () => {
  return (
    <HeroSection>
      <StyledHeroBackgroundImage className="">
        <Container>
          <StyledRow>
            <Col col={12} lg={6} noGutter>
              <HeroContentContainer>
                <HeroHeader>Uplift your Enterprise</HeroHeader>
                <HeroSubHeader>
                  An intelligent, collaborative platform designed for the
                  gathering and implementation of brilliant strategic insights
                </HeroSubHeader>
                <CtaButton>Contact Us</CtaButton>
              </HeroContentContainer>
            </Col>
          </StyledRow>
        </Container>
      </StyledHeroBackgroundImage>
    </HeroSection>
  );
};

export default Hero;
