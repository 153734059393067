import React, { useContext } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import HamburgerButton from '../HeaderBurger/HeaderBurger';
import { MobileMenuContext } from "../../pages/index";

const HeaderSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 20px;
  background-color: #000000;
  font-family: 'Open Sans', sans-serif;
  background-image: linear-gradient(53deg, #021740, #540558);
`;

const StyledContainer = styled(Container)`
  max-width: 100%;
`;

const Navigation = styled.ul`
  width: 100%;
  list-style: none;
  text-align: right;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const NavigationItem = styled.li`
  text-align: center;
  &:last-child {
    padding-right: 40px;
  }
`;

const NavigationLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  padding: 20px;
`;

const ContactButton = styled(Link)`
  box-sizing: border-box;
  padding: 20px 30px;
  background-color: #ffffff33;
  border-radius: 60px;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
`;

const HamburgerButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 100%;
  z-index: 999;
`;

const Header: React.FC = () => {
  const { mobileMenu, setMobileMenu } = useContext(MobileMenuContext);

  return (
    <HeaderSection>
      <StyledContainer>
        <Row>
          <Col col={8} lg={4} noGutter>
            <Link to="/">
              <StaticImage src="../../images/Group.svg" alt="logo" />
            </Link>
          </Col>
          <Col col={8} hiddenLgDown noGutter>
            <Navigation>
              <NavigationItem>
                <NavigationLink to="/">Home</NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <NavigationLink to="/">About</NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <NavigationLink to="/">Options</NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <ContactButton to="/">Contact</ContactButton>
              </NavigationItem>
            </Navigation>
          </Col>
          <Col col={4} hiddenLgUp noGutter>
            <HamburgerButtonContainer>
              <HamburgerButton 
                showMenu={mobileMenu.show}
                setShowMenu={setMobileMenu}
              />
            </HamburgerButtonContainer>
          </Col>
        </Row>
      </StyledContainer>
    </HeaderSection>
  );
};

export default Header;
