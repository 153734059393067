import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col, media } from "styled-bootstrap-grid";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import Cookies from "js-cookie";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import Input from "../Input/Input";
import InputTextArea from "../InputTextArea/InputTextArea";
import Button from "../Button/Button";

const ContactSection = styled.div`
  width: 100%;
  background-image: linear-gradient(49deg, #021740, #540558);
  padding: 60px 0;
  margin-top: 80px;

  .container{
    padding: 0;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
`;

const ContactFormHeader = styled.h2`
  margin: 20px 0;
  padding-left: 15px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  line-height: 36px;
`;

const submitContactFormSchema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().email().required(),
  mobilephone: yup.string(),
  message: yup.string().required(),
});

const SuccessedMessage = styled.h4`
  padding-left: 15px;

  color: #ffffff;
  font-family: "Opens Sans", sans-serif;
  font-size: 18px;
`;

const StyledCol = styled(Col)`
  text-align: right;

  ${media.md`
    text-align: left;
  `}
`;

async function formv3(data, setSubmitStatus) {
  var url =
    "https://api.hsforms.com/submissions/v3/integration/submit/19607555/fc0752fb-1217-4c3a-90e9-209a5e447268";

  let ipAddress;
  
  try {
    const ipAddressResult = await axios.get("https://ipv4.icanhazip.com/");
    ipAddress = ipAddressResult.data.replace("\n", "");
  } catch (error) {}

  // Example request JSON:
  var formData = {
    fields: [
      {
        name: "firstname",
        value: data.firstname,
      },
      {
        name: "lastname",
        value: data.lastname,
      },
      {
        name: "email",
        value: data.email,
      },
      {
        name: "mobilephone",
        value: data.mobilephone,
      },
      {
        name: "message",
        value: data.message,
      },
    ],
    context: {
      pageUri: window.location.href,
      pageName: window.location.pathname,
      ipAddress,
      hutk: Cookies.get("hubspotutk"),
    },
    headers: {
      "Content-Type": "application/json",
    },
  };

  await axios
    .post(url, formData)
    .then(() => {
      setSubmitStatus(200);
    })
    .catch((err) => {
      setSubmitStatus(err.response.status);
    });
}

const Contact = () => {
  const submitContactFormMethod = useForm({
    resolver: yupResolver(submitContactFormSchema),
  });

  const [submitStatus, setSubmitStatus] = useState();

  const onSubmit = async (data) => {
    await formv3(data, setSubmitStatus);
  };

  const isRequired = (field) => {
    return (submitContactFormSchema.fields[field] as any).exclusiveTests
      .required;
  };

  return (
    <ContactSection>
      <Container>
        <StyledRow>
          <Col col={10}>
            <ContactFormHeader>Contact Form</ContactFormHeader>
          </Col>
        </StyledRow>
        {!submitContactFormMethod.formState.isSubmitSuccessful && (
          <FormProvider {...submitContactFormMethod}>
            <form onSubmit={submitContactFormMethod.handleSubmit(onSubmit)}>
              <StyledRow>
                <Col col={10} md={5} noGutter>
                  <Input
                    label={`First Name${isRequired("firstname") ? "*" : ""}`}
                    name="firstname"
                    placeholder=""
                    disabled={
                      submitContactFormMethod.formState.isSubmitting
                        ? true
                        : false
                    }
                  />
                </Col>
                <Col col={10} md={5} noGutter>
                  <Input
                    label={`Last Name${isRequired("lastname") ? "*" : ""}`}
                    name="lastname"
                    placeholder=""
                    disabled={
                      submitContactFormMethod.formState.isSubmitting
                        ? true
                        : false
                    }
                  />
                </Col>
              </StyledRow>
              <StyledRow>
                <Col col={10} md={5} noGutter>
                  <Input
                    label={`Email${isRequired("email") ? "*" : ""}`}
                    name="email"
                    placeholder=""
                    disabled={
                      submitContactFormMethod.formState.isSubmitting
                        ? true
                        : false
                    }
                  />
                </Col>
                <Col col={10} md={5} noGutter>
                  <Input
                    label={`Phone number${
                      isRequired("mobilephone") ? "*" : ""
                    }`}
                    name="mobilephone"
                    placeholder=""
                    disabled={
                      submitContactFormMethod.formState.isSubmitting
                        ? true
                        : false
                    }
                  />
                </Col>
              </StyledRow>
              <StyledRow>
                <Col col={10} noGutter>
                  <InputTextArea
                    label={`Message${isRequired("message") ? "*" : ""}`}
                    name="message"
                    placeholder=""
                    disabled={
                      submitContactFormMethod.formState.isSubmitting
                        ? true
                        : false
                    }
                  />
                </Col>
              </StyledRow>
              <Row>
                <StyledCol col={9} offset={1} >
                  {!submitContactFormMethod.formState.isSubmitting && (
                    <Button type="submit">Submit</Button>
                  )}
                  {submitContactFormMethod.formState.isSubmitting && (
                    <Button type="submit" disabled>
                      <ClipLoader color="white" size={20} />
                    </Button>
                  )}
                </StyledCol>
              </Row>
            </form>
          </FormProvider>
        )}
        {submitContactFormMethod.formState.isSubmitSuccessful && (
          <StyledRow>
            <Col col={10}>
              <SuccessedMessage>
                Thank for submit the form, we will contact your as soon as
                possible
              </SuccessedMessage>
            </Col>
          </StyledRow>
        )}
      </Container>
    </ContactSection>
  );
};

export default Contact;
