import React, { useState } from "react";
import Header from "../components/Header";
import MobileMenu from "../components/MobileMenu/MobileMenu";
import Hero from "../components/Hero/Hero";
import SubHero from "../components/SubHero/SubHero";
import Services from "../components/Services/Services";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import styled, { createGlobalStyle } from "styled-components";

export const MobileMenuContext = React.createContext({
  mobileMenu: { show: false },
  setMobileMenu: null,
});

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    overflow-x: hidden;
  }
`;

const IndexPage = () => {
  const [mobileMenu, setMobileMenu] = useState({ show: false });

  const data = useStaticQuery(graphql`
    query {
      firstServicesImage: file(relativePath: { eq: "22.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 524)
        }
      }
      secondServicesImage: file(relativePath: { eq: "Artboard.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 524)
        }
      }
      thirdServicesImage: file(relativePath: { eq: "Artboard-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 524)
        }
      }
    }
  `);

  const firstImage = getImage(data.firstServicesImage);
  const secondImage = getImage(data.secondServicesImage);
  const thirdImage = getImage(data.thirdServicesImage);

  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <MobileMenuContext.Provider value={{ mobileMenu, setMobileMenu }}>
          <Header />
          {mobileMenu.show && <MobileMenu />}
          <Hero />
          <SubHero />
          <Services
            title="Research & analise"
            image={firstImage}
            reversed={false}
          />
          <Services
            title="Gather insights & intelligence"
            image={secondImage}
            reversed={true}
          />
          <Services
            title="Execute & implement"
            image={thirdImage}
            reversed={false}
          />
          <Contact />
          <Footer />
        </MobileMenuContext.Provider>
      </PageContainer>
    </>
  );
};

export default IndexPage;
