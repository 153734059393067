import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { StaticImage } from 'gatsby-plugin-image';

const FooterSection = styled.div`
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 7%);
  width: 100%;
  background-color: #000000;
  padding: 60px 0 40px 0;
  background-color: rgb(35 6 54);
`;
const StyledRow = styled(Row)`
  margin-bottom: 40px;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;

  ${media.sm`
        display: flex;
        justify-content: center;
    `}
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListContainer = styled.div``;

const StyledHeader = styled.h5`
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  margin-top: 0px;
`;

const StyledLink = styled.p`
  font-size: 14px;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  margin-bottom: 10px;

  ${media.sm`
        text-align: left;
    `}
`;

const StyledCopyright = styled.div`
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  color: rgba(255, 255, 255, 0.33);
`;

const Footer = () => {
  return (
    <FooterSection>
      <Container>
        <StyledRow>
          <Col col={12} sm={3} noGutter>
            <ImageContainer>
              <StaticImage src="../../images/Group-6.svg" alt="logo" />
            </ImageContainer>
          </Col>
          <StyledCol col={12} sm={3} noGutter>
            <ListContainer>
              <StyledHeader>Heading Label</StyledHeader>
              <StyledLink>Text link</StyledLink>
              <StyledLink>Text link</StyledLink>
              <StyledLink>Text link</StyledLink>
              <StyledLink>Text link</StyledLink>
              <StyledLink>Text link</StyledLink>
            </ListContainer>
          </StyledCol>
          <StyledCol col={12} sm={3} noGutter>
            <ListContainer>
              <StyledHeader>Heading Label</StyledHeader>
              <StyledLink>Text link</StyledLink>
              <StyledLink>Text link</StyledLink>
            </ListContainer>
          </StyledCol>
          <StyledCol col={12} sm={3} noGutter>
            <ListContainer>
              <StyledHeader>Heading Label</StyledHeader>
              <StyledLink>Text link</StyledLink>
              <StyledLink>Text link</StyledLink>
              <StyledLink>Text link</StyledLink>
            </ListContainer>
          </StyledCol>
        </StyledRow>
        <Row>
          <StyledCol>
            <StyledCopyright>
              Copyright © 2021 Propella. All rights reserved.
            </StyledCopyright>
          </StyledCol>
        </Row>
      </Container>
    </FooterSection>
  );
};

export default Footer;
